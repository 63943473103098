import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, doc, setDoc } from "firebase/firestore";
import FlashCard from './FlashCardListView';
import './SavedCards.css';

function SavedCards() {
  const [savedCards, setSavedCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userActivity, setUserActivity] = useState({});
  const [viewMode, setViewMode] = useState('list');
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showTranslation, setShowTranslation] = useState(false);

  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchSavedCards = async () => {
      if (!auth.currentUser) {
        setError("User not authenticated");
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const userId = auth.currentUser.uid;
        const userActivityRef = collection(firestore, `users/${userId}/activity`);
        const savedCardsQuery = query(userActivityRef, where("saved", "==", true));
        const querySnapshot = await getDocs(savedCardsQuery);

        const savedCardIds = querySnapshot.docs.map(doc => doc.id);
        const activityData = {};
        querySnapshot.docs.forEach(doc => {
          activityData[doc.id] = doc.data();
        });
        setUserActivity(activityData);

        if (savedCardIds.length === 0) {
          setSavedCards([]);
          setIsLoading(false);
          return;
        }

        const cardsRef = collection(firestore, 'cards');
        const cardsQuery = query(cardsRef, where("__name__", "in", savedCardIds));
        const cardsSnapshot = await getDocs(cardsQuery);

        const fetchedCards = cardsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setSavedCards(fetchedCards);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching saved cards:', error);
        setError("Failed to fetch saved cards");
        setIsLoading(false);
      }
    };

    fetchSavedCards();
  }, [auth, firestore]);

  const startLearning = () => {
    setViewMode('flashcard');
    setCurrentCardIndex(0);
  };

  const handleCardClick = () => {
    setShowTranslation(!showTranslation);
  };

  const handleCorrect = () => {
    if (currentCardIndex < savedCards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setShowTranslation(false);
    } else {
      setViewMode('list');
    }
  };

  const handleIncorrect = () => {
    if (currentCardIndex < savedCards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setShowTranslation(false);
    } else {
      setViewMode('list');
    }
  };

  const handleEditClick = (cardId) => {
    // Implement edit functionality here
    console.log("Edit card:", cardId);
  };

  const handleSaveToggle = async (cardId) => {
    if (!auth.currentUser) return;

    const userId = auth.currentUser.uid;
    const activityRef = doc(firestore, `users/${userId}/activity/${cardId}`);
    const newSavedState = !savedCards.some(card => card.id === cardId);

    try {
      await setDoc(activityRef, { saved: newSavedState }, { merge: true });
      setSavedCards(prev => 
        newSavedState 
          ? [...prev, savedCards.find(card => card.id === cardId)]
          : prev.filter(card => card.id !== cardId)
      );
    } catch (error) {
      console.error("Error updating saved card:", error);
    }
  };

  const renderListView = () => {
    return (
      <div className="card-list">
        <h2>Gespeichert</h2>
        <button onClick={startLearning} className="start-learning-btn">Alle Karten lernen</button>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {savedCards.length === 0 ? (
            <p>You haven't saved any cards yet.</p>
          ) : (
            savedCards.map((card) => (
              <FlashCard
                key={card.id}
                japanese={card.jp}
                romanji={card.dejp}
                german={card.de}
                info={card.info}
                correctCount={userActivity[card.id]?.correct || 0}
                incorrectCount={userActivity[card.id]?.incorrect || 0}
                onSaveToggle={() => handleSaveToggle(card.id)}
                onEditClick={() => handleEditClick(card.id)}
              />
            ))
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="japan-container">
      {isLoading ? (
        <div className="loading">Loading saved cards...</div>
      ) : error ? (
        <div className="error">{error}</div>
      ) : savedCards.length === 0 ? (
        <div className="no-saved-cards">You haven't saved any cards yet.</div>
      ) : (
        <div className="japan-container">
          {viewMode === 'list' ? (
            renderListView()
          ) : (
            <>
              <FlashCard
                key={savedCards[currentCardIndex].id}
                card={savedCards[currentCardIndex]}
                showTranslation={showTranslation}
                onCardClick={handleCardClick}
                onCorrect={handleCorrect}
                onIncorrect={handleIncorrect}
                isSaved={true}
                onSaveToggle={() => {}}
                onInfoClick={() => {}}
              />
              <div className="progress-bar-container">
                <div 
                  className="progress-bar" 
                  style={{width: `${((currentCardIndex + 1) / savedCards.length) * 100}%`}}
                ></div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default SavedCards;
