import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { GraduationCap, User, Palette } from "lucide-react";
import './Settings.css';

function Settings() {
  const [activeTab, setActiveTab] = useState('learn');
  const [hideRomanization, setHideRomanization] = useState(false);
  const [wordsPerSession, setWordsPerSession] = useState(10);
  const [dailyTarget, setDailyTarget] = useState(20);
  const [darkMode, setDarkMode] = useState(false);

  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchSettings = async () => {
      if (auth.currentUser) {
        const userSettingsRef = doc(firestore, `users/${auth.currentUser.uid}/settings/learn`);
        const settingsDoc = await getDoc(userSettingsRef);
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setHideRomanization(data.hideRomanization || false);
          setWordsPerSession(data.wordsPerSession || 10);
          setDailyTarget(data.dailyTarget || 20);
        }
      }
    };

    fetchSettings();
  }, [auth.currentUser, firestore]);

  const saveSettings = async (settings) => {
    if (auth.currentUser) {
      const userSettingsRef = doc(firestore, `users/${auth.currentUser.uid}/settings/learn`);
      await setDoc(userSettingsRef, settings, { merge: true });
    }
  };

  const handleHideRomanizationChange = async (e) => {
    const checked = e.target.checked;
    setHideRomanization(checked);
    await saveSettings({ hideRomanization: checked });
  };

  const handleWordsPerSessionChange = async (e) => {
    const value = parseInt(e.target.value, 10);
    setWordsPerSession(value);
    await saveSettings({ wordsPerSession: value });
  };

  const handleDailyTargetChange = async (e) => {
    const value = parseInt(e.target.value, 10);
    setDailyTarget(value);
    await saveSettings({ dailyTarget: value });
  };

  return (
    <div className="settings-container">
      <header className="settings-header">
        <h1>Einstellungen</h1>
      </header>
      <main className="settings-main">
        <div className="settings-tabs">
          <button 
            className={`tab-button ${activeTab === 'learn' ? 'active' : ''}`} 
            onClick={() => setActiveTab('learn')}
          >
            <GraduationCap className="tab-icon" />
            Learn
          </button>
          <button 
            className={`tab-button ${activeTab === 'account' ? 'active' : ''}`} 
            onClick={() => setActiveTab('account')}
          >
            <User className="tab-icon" />
            Account
          </button>
          <button 
            className={`tab-button ${activeTab === 'theme' ? 'active' : ''}`} 
            onClick={() => setActiveTab('theme')}
          >
            <Palette className="tab-icon" />
            Theme
          </button>
        </div>
        <div className="settings-content">
          {activeTab === 'learn' && (
            <div className="settings-card">
              <h2>Learn Settings</h2>
              <p>Customize your learning experience</p>
              <div className="settings-option">
                <input
                  type="checkbox"
                  id="hide-romanization"
                  checked={hideRomanization}
                  onChange={handleHideRomanizationChange}
                />
                <label htmlFor="hide-romanization">Lautschrift verbergen</label>
              </div>
              <div className="settings-option">
                <label htmlFor="words-per-session">Wörter pro Sitzung: {wordsPerSession}</label>
                <input
                  type="range"
                  id="words-per-session"
                  min={5}
                  max={50}
                  step={5}
                  value={wordsPerSession}
                  onChange={handleWordsPerSessionChange}
                />
              </div>
              <div className="settings-option">
                <label htmlFor="daily-target">Daily Target: {dailyTarget} words</label>
                <input
                  type="range"
                  id="daily-target"
                  min={5}
                  max={100}
                  step={5}
                  value={dailyTarget}
                  onChange={handleDailyTargetChange}
                />
              </div>
            </div>
          )}
          {activeTab === 'account' && (
            <div className="settings-card">
              <h2>Account Settings</h2>
              <p>Manage your account details</p>
              <div className="settings-option">
                <label htmlFor="email">E-Mail</label>
                <input 
                  type="email" 
                  id="email" 
                  value={auth.currentUser?.email || ''} 
                  readOnly 
                />
              </div>
              <button className="delete-progress-btn">Gesamten Fortschritt löschen</button>
            </div>
          )}
          {activeTab === 'theme' && (
            <div className="settings-card">
              <h2>Theme Settings</h2>
              <p>Customize the app appearance</p>
              <div className="settings-option">
                <input
                  type="checkbox"
                  id="dark-mode"
                  checked={darkMode}
                  onChange={(e) => setDarkMode(e.target.checked)}
                />
                <label htmlFor="dark-mode">Dark Mode</label>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default Settings;
