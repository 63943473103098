import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import RegisterPopup from './RegisterPopup';
import CreateCardPopup from './CreateCardPopup';
import { getFirestore, collection, getDocs, Timestamp, query, where, getDoc, doc } from "firebase/firestore";
import {
  LayoutDashboard,
  BookOpen,
  Folder,
  ChevronDown,
  Bookmark,
  Settings,
  PlusCircle,
  LogOut,
  UserPlus
} from "lucide-react";
import { Button } from './ui/button';
import logo from '../Japan/logo.svg'; // Import the logo

function Sidebar({ onLogout, isGuest, onGuestRegister }) {
  const [courses, setCourses] = useState([]);
  const [showCourses, setShowCourses] = useState(false);
  const [cardCounts, setCardCounts] = useState({});
  const [dueCount, setDueCount] = useState(0);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showCreateCardPopup, setShowCreateCardPopup] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const firestore = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const fetchCoursesAndCards = async () => {
      try {
        // Fetch courses
        const coursesSnapshot = await getDocs(collection(firestore, 'courses'));
        const courseList = coursesSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            date: data.date instanceof Timestamp ? data.date.toDate() : null
          };
        });

        // Sort courses by date, newest first
        const sortedCourses = courseList.sort((a, b) => {
          if (!a.date) return 1;
          if (!b.date) return -1;
          return b.date.getTime() - a.date.getTime();
        });

        setCourses(sortedCourses);

        // Fetch cards
        const cardsSnapshot = await getDocs(collection(firestore, 'cards'));
        const cardsData = cardsSnapshot.docs.map(doc => doc.data());

        // Count cards for each course
        const counts = courseList.reduce((acc, course) => {
          acc[course.id] = cardsData.filter(card => card.course === course.id).length;
          return acc;
        }, {});

        setCardCounts(counts);

        if (user) {
          await fetchUserActivity();
        }
      } catch (error) {
        console.error('Error fetching courses and cards:', error);
      }
    };

    fetchCoursesAndCards();
  }, [firestore, user]);

  const fetchUserActivity = async () => {
    try {
      const userActivityRef = collection(firestore, `users/${user.uid}/activity`);
      const activitySnapshot = await getDocs(userActivityRef);
      
      const now = new Date();
      let dueCards = 0;

      activitySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.nextReviewDate && data.nextReviewDate.toDate() <= now) {
          dueCards++;
        }
      });

      setDueCount(dueCards);
    } catch (error) {
      console.error('Error fetching user activity:', error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(firestore, 'users', user.uid));
          if (userDoc.exists()) {
            setIsAdmin(userDoc.data().admin === true);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user, firestore]);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      onLogout();
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const MenuItem = ({ icon: Icon, text, to, onClick }) => (
    <Link
      to={to}
      className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
      onClick={onClick}
    >
      <Icon className="h-5 w-5 mr-3" />
      <span>{text}</span>
    </Link>
  );

  return (
    <aside className="w-64 bg-white border-r border-gray-200 h-screen flex flex-col">
      <div className="flex items-center h-16 px-4 border-b border-gray-200">
        <img src={logo} alt="bvi logo" className="h-8 w-8" /> {/* Use the imported logo */}
        <span className="ml-2 text-lg font-semibold">Japanisch I</span>
      </div>
      <nav className="flex-1 overflow-y-auto py-4">
        <ul className="space-y-1">
          <li><MenuItem icon={LayoutDashboard} text="Dashboard" to="/" /></li>
          <li>
            <Link
              to="/practice"
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
            >
              <div className="flex items-center">
                <BookOpen className="h-5 w-5 mr-3" />
                <span>Practice</span>
              </div>
              <span className="flex items-center justify-center bg-gray-200 text-gray-700 rounded-full w-6 h-6 text-xs font-medium">
                {dueCount}
              </span>
            </Link>
          </li>
          <li>
            <div
              className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md cursor-pointer"
              onClick={() => setShowCourses(!showCourses)}
            >
              <div className="flex items-center">
                <Folder className="h-5 w-5 mr-3" />
                <span>Courses</span>
              </div>
              <ChevronDown className={`h-5 w-5 transform ${showCourses ? 'rotate-180' : ''}`} />
            </div>
            {showCourses && (
              <div className="ml-4 mt-1 space-y-1">
                {courses.map((course) => (
                  <Link
                    key={course.id}
                    to={`/course/${course.id}`}
                    className="flex items-center justify-between px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded-md"
                  >
                    <span>{course.title || `Course ${course.id}`}</span>
                    <span className="flex items-center justify-center bg-gray-200 text-gray-700 rounded-full w-6 h-6 text-xs font-medium">
                      {cardCounts[course.id] || 0}
                    </span>
                  </Link>
                ))}
              </div>
            )}
          </li>
          <li><MenuItem icon={Bookmark} text="Saved" to="/saved" /></li>
          <li><MenuItem icon={Settings} text="Settings" to="/settings" /></li>
        </ul>
      </nav>
      <div className="p-4 border-t border-gray-200">
        {isAdmin && (
          <Button
            className="w-full mb-2 bg-gray-800 text-white hover:bg-gray-700 flex items-center justify-center"
            onClick={() => setShowCreateCardPopup(true)}
          >
            <PlusCircle className="mr-2 h-5 w-5" />
            Create
          </Button>
        )}
        {isGuest ? (
          <Button
            variant="outline"
            className="w-full flex items-center justify-center"
            onClick={() => setShowRegisterPopup(true)}
          >
            <UserPlus className="mr-2 h-5 w-5" />
            Register
          </Button>
        ) : (
          <Button
            variant="outline"
            className="w-full flex items-center justify-center"
            onClick={handleLogout}
          >
            <LogOut className="mr-2 h-5 w-5" />
            Logout
          </Button>
        )}
      </div>
      {showRegisterPopup && (
        <RegisterPopup 
          onClose={() => setShowRegisterPopup(false)} 
          onRegister={onGuestRegister}
        />
      )}
      {showCreateCardPopup && (
        <CreateCardPopup 
          isOpen={showCreateCardPopup}
          onClose={() => setShowCreateCardPopup(false)}
          courses={courses}
        />
      )}
    </aside>
  );
}

export default Sidebar;
