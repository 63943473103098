import React from "react"
import { Heart, Edit } from "lucide-react"
import { Card, CardContent, CardFooter, CardHeader } from "./ui/card"
import { Button } from "./ui/button"

export default function FlashCardListView({ 
  id,
  japanese = "こんにちは",
  romanji = "Konnichiwa",
  german = "Guten Tag",
  info,
  correctCount = 5,
  incorrectCount = 2,
  nextReviewDate,
  onSaveToggle,
  onEditClick
}) {
  return (
    <Card className="w-full max-w-sm mx-auto bg-gray-50">
      <CardHeader className="flex justify-between items-center p-4">
        <div className="flex space-x-2">
          <Button variant="ghost" size="icon" aria-label="Favorite" onClick={onSaveToggle}>
            <Heart className="h-4 w-4" />
          </Button>
          <Button 
            variant="ghost" 
            size="icon" 
            aria-label="Edit" 
            onClick={() => onEditClick({id, jp: japanese, dejp: romanji, de: german, info})}
          >
            <Edit className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="text-center space-y-4">
        <div className="space-y-2">
          <p className="text-xl font-bold font-italic">{japanese}</p>
          <p className="text-lg">{romanji}</p>
          <p className="text-xl text-muted-foreground">{german}</p>
        </div>
        {info && info.trim() !== "" && (
          <div className="bg-gray-100 p-2 rounded-md text-sm text-muted-foreground">
            <p>{info}</p>
          </div>
        )}
      </CardContent>
      <CardFooter className="flex flex-col items-center text-sm text-muted-foreground">
        <div className="flex space-x-4 mb-2">
          <p>Correct: {correctCount}</p>
          <p>Incorrect: {incorrectCount}</p>
        </div>
        {nextReviewDate && (
          <p className="text-xs italic text-gray-400">
            Next review: {new Date(nextReviewDate).toLocaleDateString()}
          </p>
        )}
      </CardFooter>
    </Card>
  )
}
