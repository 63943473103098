import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, deleteUser, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc, deleteDoc, collection, getDocs } from "firebase/firestore";
import './RegisterPopup.css';

function RegisterPopup({ onClose, onRegister }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const firestore = getFirestore();

    try {
      // Store the current guest user
      const guestUser = auth.currentUser;
      const guestId = guestUser.uid;

      // Create new user
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const newUser = userCredential.user;

      // Get current guest data
      const guestDataRef = doc(firestore, `users/${guestId}`);
      const guestDataSnapshot = await getDoc(guestDataRef);
      const guestData = guestDataSnapshot.data();

      // Create new user document and transfer guest data
      if (guestData) {
        await setDoc(doc(firestore, `users/${newUser.uid}`), {
          ...guestData,
          email: newUser.email,
          createdAt: new Date().toISOString(),
        });

        // Transfer activity data
        const guestActivitySnapshot = await getDocs(collection(firestore, `users/${guestId}/activity`));
        guestActivitySnapshot.forEach(async (activityDoc) => {
          await setDoc(doc(firestore, `users/${newUser.uid}/activity/${activityDoc.id}`), activityDoc.data());
        });
      } else {
        // If no guest data, create a new user document
        await setDoc(doc(firestore, `users/${newUser.uid}`), {
          email: newUser.email,
          createdAt: new Date().toISOString(),
        });
      }

      // Delete guest data from database
      await deleteDoc(doc(firestore, `users/${guestId}`));

      // Delete guest user from authentication
      await deleteUser(guestUser);

      // Sign in the new user
      await signInWithEmailAndPassword(auth, email, password);

      // Call the onRegister callback with the new user
      onRegister(newUser);

      onClose();
    } catch (error) {
      console.error("Error during registration:", error);
      setError(error.message);
    }
  };

  return (
    <div className="register-popup">
      <div className="register-popup-content">
        <h2>Register</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleRegister}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Register</button>
        </form>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
}

export default RegisterPopup;