import React, { useState, useEffect } from 'react';
import { Heart, Info, Check, X, ChevronDown, Volume2 } from "lucide-react";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";

function FlashCard({ card, showTranslation, onCardClick, onCorrect, onIncorrect, isSaved, onSaveToggle, onInfoClick, isMobile }) {
  const [hideRomanization, setHideRomanization] = useState(false);
  const [showRomanization, setShowRomanization] = useState(false);
  const [isRomanizationLoaded, setIsRomanizationLoaded] = useState(false);

  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchSettings = async () => {
      if (auth.currentUser) {
        const userSettingsRef = doc(firestore, `users/${auth.currentUser.uid}/settings/learn`);
        const settingsDoc = await getDoc(userSettingsRef);
        if (settingsDoc.exists()) {
          const hideRomanizationSetting = settingsDoc.data().hideRomanization || false;
          setHideRomanization(hideRomanizationSetting);
          setShowRomanization(!hideRomanizationSetting);
        }
      }
      setIsRomanizationLoaded(true);
    };

    fetchSettings();
  }, [auth.currentUser, firestore]);

  useEffect(() => {
    setShowRomanization(!hideRomanization);
  }, [card, hideRomanization]);

  const speak = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'ja-JP';
    window.speechSynthesis.speak(utterance);
  };

  const handleSpeakClick = (e) => {
    e.stopPropagation();
    speak(card.jp);
  };

  return (
    <Card 
  className="w-full max-w-md mx-auto flex flex-col justify-between bg-gray-50"
  onClick={onCardClick}
>
      <CardContent className="p-6 relative">
        <div className="flex justify-between items-start mb-8">
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8 absolute top-2 left-2"
            onClick={(e) => {
              e.stopPropagation();
              onSaveToggle(card.id);
            }}
          >
            <Heart className={`h-6 w-6 ${isSaved ? "text-red-500 fill-current" : "text-gray-400"}`} />
            <span className="sr-only">Save card</span>
          </Button>
          {card.info && (
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8 absolute top-2 right-2"
              onClick={(e) => {
                e.stopPropagation();
                onInfoClick(card.info);
              }}
            >
              <Info className="h-4 w-4" />
              <span className="sr-only">More information</span>
            </Button>
          )}
        </div>
        <div className="text-center mt-8 mb-6">
          {!showTranslation ? (
            <>
              <div className="flex justify-center items-center mb-2">
                <h2 className="text-4xl font-bold text-gray-900">{card.jp}</h2>
                <Button variant="ghost" size="icon" className="ml-2" onClick={handleSpeakClick}>
                  <Volume2 className="h-4 w-4" />
                  <span className="sr-only">Speak</span>
                </Button>
              </div>
              {!hideRomanization && isRomanizationLoaded && (
                <p className={`mt-2 text-lg text-gray-500 transition-opacity duration-300 ${showRomanization ? 'opacity-100' : 'opacity-0'}`}>
                  {card.dejp}
                </p>
              )}
              {hideRomanization && (
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowRomanization(!showRomanization);
                  }}
                >
                  {showRomanization ? "Hide" : "Show"} Romanji
                  <ChevronDown className={`ml-2 h-4 w-4 transition-transform ${showRomanization ? "rotate-180" : ""}`} />
                </Button>
              )}
              {hideRomanization && showRomanization && isRomanizationLoaded && (
                <p className={`mt-2 text-lg text-gray-500 transition-opacity duration-300 ${showRomanization ? 'opacity-100' : 'opacity-0'}`}>
                  {card.dejp}
                </p>
              )}
            </>
          ) : (
            <>
              <div className="mb-4">
                <p className="text-lg text-gray-500">{card.dejp}</p>
                <h2 className="text-4xl font-bold text-gray-900">{card.jp}</h2>
              </div>
              <p className="text-xl mb-6">{card.de}</p>
            </>
          )}
        </div>
        {showTranslation && (
          <div className="flex justify-center space-x-4">
            <Button 
              variant="outline" 
              size="icon" 
              className="h-10 w-10 rounded-full border-2 border-gray-300 bg-transparent hover:bg-gray-300 transition-colors duration-300" 
              onClick={onIncorrect}
            >
              <X className="h-4 w-4 text-red-500" />
              <span className="sr-only">Incorrect</span>
            </Button>
            <Button 
              variant="outline" 
              size="icon" 
              className="h-10 w-10 rounded-full border-2 border-gray-300 bg-transparent hover:bg-gray-300 transition-colors duration-300" 
              onClick={onCorrect}
            >
              <Check className="h-4 w-4 text-green-500" />
              <span className="sr-only">Correct</span>
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default FlashCard;
