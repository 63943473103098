import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Plus, LogOut } from 'lucide-react';
import { getFirestore, collection, getDocs } from "firebase/firestore";
import CreateCardPopup from './CreateCardPopup';

function MobileMenu({ onLogout, isGuest, onGuestRegister }) {
  const [isOpen, setIsOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [showCreateCardPopup, setShowCreateCardPopup] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      const firestore = getFirestore();
      const coursesCollection = collection(firestore, 'courses');
      const coursesSnapshot = await getDocs(coursesCollection);
      const coursesList = coursesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCourses(coursesList);
    };

    fetchCourses();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCreateClick = () => {
    setShowCreateCardPopup(true);
    setIsOpen(false);
  };

  return (
    <>
      <button 
        className="fixed top-4 right-4 z-50 p-2 bg-white rounded-full shadow-md"
        onClick={toggleMenu}
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>
      {isOpen && (
        <div className="fixed inset-0 bg-white z-40 p-4 overflow-y-auto">
          <nav className="flex flex-col space-y-4">
            <Link to="/" onClick={toggleMenu} className="text-xl font-semibold">Dashboard</Link>
            <Link to="/practice" onClick={toggleMenu} className="text-xl font-semibold">Practice</Link>
            <Link to="/saved" onClick={toggleMenu} className="text-xl font-semibold">Saved Cards</Link>
            <Link to="/settings" onClick={toggleMenu} className="text-xl font-semibold">Settings</Link>
            
            <div className="mt-4 mb-2">
              <h3 className="text-lg font-semibold mb-2">Courses</h3>
              {courses.map(course => (
                <Link 
                  key={course.id} 
                  to={`/course/${course.id}`} 
                  onClick={toggleMenu}
                  className="block py-2 text-lg"
                >
                  {course.title}
                </Link>
              ))}
            </div>

            <button 
              onClick={handleCreateClick}
              className="flex items-center justify-center w-full py-3 mt-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-md text-lg"
            >
              <Plus size={20} className="mr-2" />
              Create
            </button>

            {isGuest ? (
              <button 
                onClick={() => { onGuestRegister(); toggleMenu(); }}
                className="flex items-center justify-center w-full py-3 mt-2 bg-green-500 text-white rounded-md text-lg"
              >
                Register
              </button>
            ) : (
              <button 
                onClick={() => { onLogout(); toggleMenu(); }}
                className="flex items-center justify-center w-full py-3 mt-2 bg-red-500 text-white rounded-md text-lg"
              >
                <LogOut size={20} className="mr-2" />
                Logout
              </button>
            )}
          </nav>
        </div>
      )}
      {showCreateCardPopup && (
        <CreateCardPopup 
          isOpen={showCreateCardPopup}
          onClose={() => setShowCreateCardPopup(false)}
          courses={courses}
        />
      )}
    </>
  );
}

export default MobileMenu;
