import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FlashCardListView from '../components/FlashCardListView';
import FlashCard from '../components/FlashCard';
import './Japan.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart, faEdit } from '@fortawesome/free-solid-svg-icons';
import CreateCardPopup from '../components/CreateCardPopup';

// Add this near the top of the file
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5001/japan-flash-cards/us-central1';

function Japan({ startInPracticeMode = false }) {
  const [cards, setCards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showTranslation, setShowTranslation] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [savedCards, setSavedCards] = useState({});
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [currentInfo, setCurrentInfo] = useState('');
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'flashcard'
  const [userActivity, setUserActivity] = useState({});
  const [courseTitle, setCourseTitle] = useState('');
  const [sortedCards, setSortedCards] = useState([]);
  const [showCreateCardPopup, setShowCreateCardPopup] = useState(false);
  const [editingCard, setEditingCard] = useState(null);
  const [courses, setCourses] = useState([]);
  const [wordsPerSession, setWordsPerSession] = useState(10);
  const [currentSessionCards, setCurrentSessionCards] = useState([]);
  const [sessionCompleted, setSessionCompleted] = useState(false);
  const [isLearningMode, setIsLearningMode] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        console.log("User authenticated:", currentUser.uid);
      } else {
        console.log("No user authenticated, redirecting to login");
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  useEffect(() => {
    // Reset error state when component mounts or courseId changes
    setError(null);
    setCards([]);
    setSortedCards([]);
    setIsLoading(true);

    if (user) {
      const fetchCards = async () => {
        try {
          console.log("Fetching cards for course:", courseId);
          
          const cardsRef = collection(firestore, 'cards');
          let cardsQuery;
          
          if (courseId) {
            cardsQuery = query(cardsRef, where("course", "==", courseId));
          } else {
            cardsQuery = query(cardsRef);
          }
          
          const querySnapshot = await getDocs(cardsQuery);
          const fetchedCards = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          
          console.log('Received data:', fetchedCards);
          if (fetchedCards.length > 0) {
            setCards(fetchedCards);
            console.log("Cards set in state:", fetchedCards);
          } else {
            console.log('No cards received from server');
            setError('No cards available for this course');
          }

          // Fetch user activity for these cards
          await fetchUserActivity(fetchedCards.map(card => card.id));

        } catch (error) {
          console.error('Error fetching cards:', error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchCards();
    }
  }, [user, courseId, firestore]);

  // New function to fetch user activity
  const fetchUserActivity = async (cardIds) => {
    if (!user) return;

    const userActivityRef = collection(firestore, `users/${user.uid}/activity`);
    const activityData = {};
    const savedCardsData = {};

    // Initialize activity data for all cards
    cardIds.forEach(id => {
      activityData[id] = { correct: 0, incorrect: 0, saved: false };
      savedCardsData[id] = false;
    });

    try {
      const querySnapshot = await getDocs(userActivityRef);
      querySnapshot.forEach((doc) => {
        if (cardIds.includes(doc.id)) {
          activityData[doc.id] = doc.data();
          savedCardsData[doc.id] = doc.data().saved || false;
        }
      });
    } catch (error) {
      console.error("Error fetching user activity:", error);
    }

    setUserActivity(activityData);
    setSavedCards(savedCardsData);
  };

  useEffect(() => {
    if (user) {
      const fetchSavedCards = async () => {
        const userActivityRef = collection(firestore, `users/${user.uid}/activity`);
        const querySnapshot = await getDocs(userActivityRef);
        const savedCardsData = {};
        querySnapshot.forEach((doc) => {
          savedCardsData[doc.id] = doc.data().saved || false;
        });
        setSavedCards(savedCardsData);
      };
      fetchSavedCards();
    }
  }, [user, firestore]);

  useEffect(() => {
    if (user) {
      const fetchUserActivity = async () => {
        const userActivityRef = collection(firestore, `users/${user.uid}/activity`);
        const querySnapshot = await getDocs(userActivityRef);
        const activityData = {};
        querySnapshot.forEach((doc) => {
          activityData[doc.id] = doc.data();
        });
        setUserActivity(activityData);
      };
      fetchUserActivity();
    }
  }, [user, firestore]);

  useEffect(() => {
    if (user && courseId) {
      const fetchCourseTitle = async () => {
        try {
          const courseRef = doc(firestore, 'courses', courseId);
          const courseDoc = await getDoc(courseRef);
          if (courseDoc.exists()) {
            setCourseTitle(courseDoc.data().title);
          } else {
            console.error('Course not found');
            setCourseTitle('Unknown Course');
          }
        } catch (error) {
          console.error('Error fetching course title:', error);
          setCourseTitle('Error Loading Course');
        }
      };

      fetchCourseTitle();
    } else if (!courseId) {
      setCourseTitle('All Cards');
    }
  }, [user, courseId, firestore]);

  const sortCards = (cardsToSort) => {
    return cardsToSort.sort((a, b) => {
      const activityA = userActivity[a.id] || {};
      const activityB = userActivity[b.id] || {};
      
      // For practice mode, only consider learned cards
      if (startInPracticeMode) {
        if (!activityA.correct && !activityA.incorrect) return 1;
        if (!activityB.correct && !activityB.incorrect) return -1;
      } else {
        // For regular mode, prioritize unlearned cards
        if (!activityA.correct && !activityA.incorrect) return -1;
        if (!activityB.correct && !activityB.incorrect) return 1;
      }
      
      // Then sort by due date
      const dueA = activityA.nextReviewDate ? activityA.nextReviewDate.toDate() : new Date(0);
      const dueB = activityB.nextReviewDate ? activityB.nextReviewDate.toDate() : new Date(0);
      
      return dueA - dueB;
    });
  };

  const startLearning = () => {
    let cardsForSession;
    if (startInPracticeMode) {
      // Filter for learned cards only
      cardsForSession = sortedCards.filter(card => {
        const activity = userActivity[card.id];
        return activity && (activity.correct || activity.incorrect);
      });
    } else {
      cardsForSession = sortedCards;
    }
    
    const newSessionCards = cardsForSession.slice(0, wordsPerSession);
    setCurrentSessionCards(newSessionCards);
    setCurrentCardIndex(0);
    setShowTranslation(false);
    setSessionCompleted(false);
    setIsLearningMode(true);
    setViewMode('flashcard');
  };

  const handleCardClick = () => {
    setShowTranslation(true);
    console.log("Card revealed:", sortedCards[currentCardIndex]);
  };

  const handleNextCard = () => {
    const nextIndex = currentCardIndex + 1;
    if (nextIndex < currentSessionCards.length) {
      setCurrentCardIndex(nextIndex);
      setShowTranslation(false);
    } else {
      setSessionCompleted(true);
    }
  };

  const updateUserActivity = async (isCorrect) => {
    if (auth.currentUser && sortedCards[currentCardIndex]) {
      const userId = auth.currentUser.uid;
      const cardId = sortedCards[currentCardIndex].id;
      const activityRef = doc(firestore, `users/${userId}/activity/${cardId}`);
      
      try {
        const activityDoc = await getDoc(activityRef);
        const currentData = activityDoc.exists() ? activityDoc.data() : { correct: 0, incorrect: 0, saved: false };
        
        const updates = isCorrect 
          ? { correct: (currentData.correct || 0) + 1 }
          : { incorrect: (currentData.incorrect || 0) + 1 };
        
        const newCorrect = (currentData.correct || 0) + (isCorrect ? 1 : 0);
        const newIncorrect = (currentData.incorrect || 0) + (isCorrect ? 0 : 1);
        const delta = newCorrect - newIncorrect;
        
        const nextReviewDate = calculateNextReviewDate(delta);
        
        await setDoc(activityRef, { 
          ...currentData, 
          ...updates, 
          nextReviewDate 
        }, { merge: true });

        // Update daily stats
        await updateDailyStats(isCorrect);
      } catch (error) {
        console.error("Error updating user activity:", error);
      }
    } else {
      console.warn("Unable to update user activity: User not authenticated or card not available");
    }
  };

  const calculateNextReviewDate = (delta) => {
    const today = new Date();
    let daysToAdd;

    if (delta <= 0) daysToAdd = 1;
    else if (delta === 1) daysToAdd = 2;
    else if (delta === 2) daysToAdd = 4;
    else if (delta === 3) daysToAdd = 7;
    else daysToAdd = 14;

    today.setDate(today.getDate() + daysToAdd);
    return Timestamp.fromDate(today);
  };

  const handleCorrect = (e) => {
    e.stopPropagation();
    updateUserActivity(true);
    handleNextCard();
  };

  const handleIncorrect = (e) => {
    e.stopPropagation();
    updateUserActivity(false);
    handleNextCard();
  };

  const handleSaveToggle = async (cardId) => {
    if (!user) return;

    const activityRef = doc(firestore, `users/${user.uid}/activity/${cardId}`);
    const newSavedState = !savedCards[cardId];

    try {
      await setDoc(activityRef, { saved: newSavedState }, { merge: true });
      setSavedCards(prev => ({...prev, [cardId]: newSavedState}));
    } catch (error) {
      console.error("Error updating saved card:", error);
    }
  };

  const handleInfoClick = (info) => {
    setCurrentInfo(info);
    setShowInfoPopup(true);
  };

  const handleEditClick = (card) => {
    setEditingCard(card);
    setShowCreateCardPopup(true);
  };

  const handleCloseCreateCardPopup = () => {
    setShowCreateCardPopup(false);
    setEditingCard(null);
  };

  const renderListView = () => {
    return (
      <div className="card-list">
        <h2>{courseTitle}</h2>
        <button onClick={startLearning} className="start-learning-btn">Alle Karten lernen</button>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sortCards(cards).map((card) => (
            <FlashCardListView
              key={card.id}
              id={card.id}
              japanese={card.jp}
              romanji={card.dejp}
              german={card.de}
              info={card.info}
              correctCount={userActivity[card.id]?.correct || 0}
              incorrectCount={userActivity[card.id]?.incorrect || 0}
              onSaveToggle={() => handleSaveToggle(card.id)}
              onEditClick={() => handleEditClick(card)}
            />
          ))}
        </div>
      </div>
    );
  };

  const isPracticeMode = window.location.pathname === '/practice';

  useEffect(() => {
    console.log("Current card index:", currentCardIndex);
    console.log("Current card:", sortedCards[currentCardIndex]);
  }, [currentCardIndex, sortedCards]);

  useEffect(() => {
    if (user) {
      const fetchCourses = async () => {
        try {
          const coursesRef = collection(firestore, 'courses');
          const querySnapshot = await getDocs(coursesRef);
          const fetchedCourses = querySnapshot.docs.map(doc => ({
            id: doc.id,
            title: doc.data().title,
            // ... any other course data you need
          }));
          setCourses(fetchedCourses);
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      };

      fetchCourses();
    }
  }, [user, firestore]);

  const handleCreateNewCard = () => {
    setEditingCard(null);
    setShowCreateCardPopup(true);
  };

  // Add this function to update daily stats
  const updateDailyStats = async (isCorrect) => {
    if (!user) return;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayTimestamp = Timestamp.fromDate(today);

    const statsRef = doc(firestore, `users/${user.uid}/stats/${today.toISOString().split('T')[0]}`);

    try {
      const statsDoc = await getDoc(statsRef);
      if (statsDoc.exists()) {
        // Update existing stats
        await setDoc(statsRef, {
          [isCorrect ? 'correct' : 'incorrect']: statsDoc.data()[isCorrect ? 'correct' : 'incorrect'] + 1
        }, { merge: true });
      } else {
        // Create new stats document for today
        await setDoc(statsRef, {
          date: todayTimestamp,
          correct: isCorrect ? 1 : 0,
          incorrect: isCorrect ? 0 : 1
        });
      }
    } catch (error) {
      console.error("Error updating daily stats:", error);
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      if (auth.currentUser) {
        const userSettingsRef = doc(firestore, `users/${auth.currentUser.uid}/settings/learn`);
        const settingsDoc = await getDoc(userSettingsRef);
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setWordsPerSession(data.wordsPerSession || 10);
        }
      }
    };

    fetchSettings();
  }, [auth.currentUser, firestore]);

  const startNewSession = () => {
    const newSessionCards = sortedCards.slice(0, wordsPerSession);
    setCurrentSessionCards(newSessionCards);
    setCurrentCardIndex(0);
    setShowTranslation(false);
    setSessionCompleted(false);
  };

  useEffect(() => {
    if (startInPracticeMode && cards.length > 0 && !isLearningMode) {
      startLearning();
    }
  }, [startInPracticeMode, cards, isLearningMode]);

  useEffect(() => {
    if (cards.length > 0 && Object.keys(userActivity).length > 0) {
      const sorted = sortCards([...cards]);
      setSortedCards(sorted);
      if (startInPracticeMode) {
        const learnedCards = sorted.filter(card => {
          const activity = userActivity[card.id];
          return activity && (activity.correct || activity.incorrect);
        });
        setCurrentSessionCards(learnedCards.slice(0, wordsPerSession));
      } else {
        setCurrentSessionCards(sorted.slice(0, wordsPerSession));
      }
      setCurrentCardIndex(0);
      setShowTranslation(false);
    }
  }, [cards, userActivity, wordsPerSession, startInPracticeMode]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="japan-scroll-container">
      <div className={isLearningMode ? "practice-container" : "japan-container"}>
        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : currentSessionCards.length === 0 ? (
          <div className="error">
            {startInPracticeMode 
              ? "No learned cards available for practice. Try learning some cards first!" 
              : "No cards available for this session"}
          </div>
        ) : !isLearningMode ? (
          renderListView()
        ) : sessionCompleted ? (
          <div className="session-completed">
            <h2>Good Job!</h2>
            <div className="session-completed-buttons">
              <button onClick={startLearning} className="more-learn-btn">Mehr lernen</button>
              <button onClick={() => setIsLearningMode(false)} className="back-to-list-btn">Zurück zur Liste</button>
            </div>
          </div>
        ) : (
          <>
            <FlashCard
              key={currentSessionCards[currentCardIndex].id}
              card={currentSessionCards[currentCardIndex]}
              showTranslation={showTranslation}
              onCardClick={handleCardClick}
              onCorrect={handleCorrect}
              onIncorrect={handleIncorrect}
              isSaved={savedCards[currentSessionCards[currentCardIndex].id] || false}
              onSaveToggle={() => handleSaveToggle(currentSessionCards[currentCardIndex].id)}
              onInfoClick={() => handleInfoClick(currentSessionCards[currentCardIndex].info)}
              isMobile={isMobile}
            />
            <div className="progress-bar-container">
              <div 
                className="progress-bar" 
                style={{width: `${((currentCardIndex + 1) / currentSessionCards.length) * 100}%`}}
              ></div>
              <div className="progress-text">
                {currentCardIndex + 1}/{currentSessionCards.length}
              </div>
            </div>
          </>
        )}
        {showInfoPopup && (
          <div className="info-popup">
            <div className="info-popup-content">
              <h3>Additional Information</h3>
              <p>{currentInfo}</p>
              <button onClick={() => setShowInfoPopup(false)}>Close</button>
            </div>
          </div>
        )}
        {showCreateCardPopup && (
          <CreateCardPopup 
            onClose={handleCloseCreateCardPopup}
            courses={courses}
            editCard={editingCard}
            currentCourseId={courseId}  // Pass the current course ID
          />
        )}
      </div>
    </div>
  );
}

export default Japan;
