import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider, 
  signInAnonymously, 
  setPersistence, 
  browserLocalPersistence,
  // Remove the unused import
  // onAuthStateChanged
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from '../config/firebaseConfig';
import './Login.css';
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

// Set persistence
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();

  const createUserDocument = async (user) => {
    const userRef = doc(firestore, 'users', user.uid);
    const userSnap = await getDoc(userRef);
    
    if (!userSnap.exists()) {
      await setDoc(userRef, {
        email: user.email,
        createdAt: new Date().toISOString(),
        admin: false, // Add this line to set admin to false by default
        // Add any other initial user data you want to store
      });
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        onLogin({ email: user.email });
        navigate('/'); // Change this line to redirect to the root path
      }
    });

    return () => unsubscribe();
  }, [onLogin, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      if (isRegistering) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await createUserDocument(userCredential.user);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      onLogin({ email });
      navigate('/'); // Change this line to redirect to the root path
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      await createUserDocument(userCredential.user);
      onLogin({ email: auth.currentUser.email });
      navigate('/'); // Change this line to redirect to the root path
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGuestLogin = async () => {
    try {
      const userCredential = await signInAnonymously(auth);
      await createUserDocument(userCredential.user);
      onLogin({ ...userCredential.user, isGuest: true });
      navigate('/'); // Change this line to redirect to the root path
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>{isRegistering ? 'Register' : 'Login'}</h2>
        {error && <p className="error">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}"
          title="Must contain at least 8 characters, including uppercase, lowercase, number, and special character"
        />
        <button type="submit">{isRegistering ? 'Register' : 'Login'}</button>
        <p onClick={() => setIsRegistering(!isRegistering)} className="toggle-register">
          {isRegistering ? 'Already have an account? Login' : 'Need an account? Register'}
        </p>
        <div className="or-divider">
          <span>OR</span>
        </div>
        <button type="button" className="google-login" onClick={handleGoogleLogin}>
          <span className="google-icon"></span>
          Mit Google anmelden
        </button>
        <button type="button" className="guest-login" onClick={handleGuestLogin}>
          Als Gast fortfahren
        </button>
      </form>
    </div>
  );
}

export default Login;
