import React, { useState, useEffect } from 'react';
import { Brain, Calendar, BookOpen } from "lucide-react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, Timestamp, doc, getDoc } from "firebase/firestore";

const data = [
  { name: 'Mon', cards: 20 },
  { name: 'Tue', cards: 35 },
  { name: 'Wed', cards: 25 },
  { name: 'Thu', cards: 40 },
  { name: 'Fri', cards: 30 },
  { name: 'Sat', cards: 45 },
  { name: 'Sun', cards: 50 },
];

function Card({ children, className }) {
  return <div className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`}>{children}</div>;
}

function CardHeader({ children, className }) {
  return <div className={`flex flex-row items-center justify-between space-y-0 p-6 ${className}`}>{children}</div>;
}

function CardTitle({ children, className }) {
  return <h3 className={`text-sm font-medium ${className}`}>{children}</h3>;
}

function CardContent({ children }) {
  return <div className="p-6 pt-0">{children}</div>;
}

function Progress({ value, className }) {
  return (
    <div className={`h-2 w-full bg-blue-200 ${className}`}>
      <div className="h-full bg-blue-600" style={{ width: `${value}%` }}></div>
    </div>
  );
}

function RadialProgress({ value }) {
  const circumference = 2 * Math.PI * 45; // 45 is the radius
  const strokeDashoffset = circumference - (value / 100) * circumference;

  return (
    <div className="relative inline-flex">
      <svg className="w-24 h-24">
        <circle
          className="text-gray-300"
          strokeWidth="5"
          stroke="currentColor"
          fill="transparent"
          r="45"
          cx="50%"
          cy="50%"
        />
        <circle
          className="text-blue-600"
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r="45"
          cx="50%"
          cy="50%"
          style={{ transform: "rotate(-90deg)", transformOrigin: "50% 50%" }}
        />
      </svg>
      <span className="absolute inset-0 flex items-center justify-center text-lg font-bold">
        {value}%
      </span>
    </div>
  );
}

function Dashboard() {
  const [todayProgress, setTodayProgress] = useState(0);
  const [wordsDue, setWordsDue] = useState(0);
  const [weeklyActivity, setWeeklyActivity] = useState([]);
  const [wordsKnown, setWordsKnown] = useState(0);
  const [courseCompletions, setCourseCompletions] = useState([]);
  const [overallCompletion, setOverallCompletion] = useState(0);
  const [dailyTarget, setDailyTarget] = useState(20);

  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!auth.currentUser) return;

      const userId = auth.currentUser.uid;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayTimestamp = Timestamp.fromDate(today);

      // Fetch user settings
      const userSettingsRef = doc(firestore, `users/${userId}/settings/learn`);
      const settingsDoc = await getDoc(userSettingsRef);
      if (settingsDoc.exists()) {
        setDailyTarget(settingsDoc.data().dailyTarget || 20);
      }

      // Fetch today's progress
      const statsRef = collection(firestore, `users/${userId}/stats`);
      const todayStatsQuery = query(statsRef, where("date", "==", todayTimestamp));
      const todayStatsSnapshot = await getDocs(todayStatsQuery);
      
      if (!todayStatsSnapshot.empty) {
        const todayStats = todayStatsSnapshot.docs[0].data();
        setTodayProgress((todayStats.correct || 0) + (todayStats.incorrect || 0));
      }

      // Fetch words due
      const activityRef = collection(firestore, `users/${userId}/activity`);
      const activitySnapshot = await getDocs(activityRef);
      const dueCards = activitySnapshot.docs.filter(doc => {
        const data = doc.data();
        return data.nextReviewDate && data.nextReviewDate.toDate() <= today;
      }).length;
      setWordsDue(dueCards);

      // Fetch weekly activity
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
      const weeklyStatsQuery = query(statsRef, where("date", ">=", Timestamp.fromDate(sevenDaysAgo)));
      const weeklyStatsSnapshot = await getDocs(weeklyStatsQuery);
      
      const weeklyData = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        weeklyData.unshift({ name: date.toLocaleDateString('en-US', { weekday: 'short' }), cards: 0 });
      }

      weeklyStatsSnapshot.forEach(doc => {
        const data = doc.data();
        const date = data.date.toDate();
        const index = weeklyData.findIndex(item => item.name === date.toLocaleDateString('en-US', { weekday: 'short' }));
        if (index !== -1) {
          weeklyData[index].cards = (data.correct || 0) + (data.incorrect || 0);
        }
      });

      setWeeklyActivity(weeklyData);

      // Update the words known calculation
      const userActivityRef = collection(firestore, `users/${userId}/activity`);
      const userActivitySnapshot = await getDocs(userActivityRef);
      const knownWords = userActivitySnapshot.docs.filter(doc => {
        const data = doc.data();
        return (data.correct || 0) >= 5;
      }).length;
      setWordsKnown(knownWords);

      // Fetch courses and calculate completions
      const coursesRef = collection(firestore, 'courses');
      const coursesSnapshot = await getDocs(coursesRef);
      const courses = coursesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const cardsRef = collection(firestore, 'cards');
      const cardsSnapshot = await getDocs(cardsRef);
      const cards = cardsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Rename this to avoid the duplicate declaration
      const userActivityForCompletions = await getDocs(userActivityRef);
      const userActivity = userActivityForCompletions.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});

      let totalPoints = 0;
      let completedPoints = 0;

      const completions = courses.map(course => {
        const courseCards = cards.filter(card => card.course === course.id);
        const courseTotalPoints = courseCards.length * 5;
        let courseCompletedPoints = 0;

        courseCards.forEach(card => {
          const activity = userActivity[card.id] || {};
          const correct = activity.correct || 0;
          const incorrect = activity.incorrect || 0;
          const cardScore = Math.min(5, Math.max(0, correct >= 5 ? 5 : correct - incorrect));
          courseCompletedPoints += cardScore;
        });

        totalPoints += courseTotalPoints;
        completedPoints += courseCompletedPoints;

        const completionPercentage = Math.round((courseCompletedPoints / courseTotalPoints) * 100) || 0;

        return {
          id: course.id,
          title: course.title,
          completion: completionPercentage
        };
      });

      setCourseCompletions(completions);
      setOverallCompletion(Math.round((completedPoints / totalPoints) * 100) || 0);
    };

    fetchDashboardData();
  }, [auth.currentUser, firestore]);

  return (
    <div className="flex flex-col min-h-screen bg-transparent">
      <main className="flex-1 py-6 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto space-y-6">
          <Card className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
            <CardHeader className="pb-2">
              <CardTitle className="text-lg font-medium">Today's Progress</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-6xl font-bold">{todayProgress}</div>
              <p className="text-blue-100 mt-2">Cards reviewed today</p>
              <Progress 
                value={Math.min((todayProgress / dailyTarget) * 100, 100)} 
                className="mt-4 bg-blue-200" 
              />
              <p className="text-blue-100 mt-2">Daily Target: {dailyTarget} words</p>
            </CardContent>
          </Card>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            <Card className="bg-white bg-opacity-80">
              <CardHeader>
                <CardTitle>Words Known</CardTitle>
                <Brain className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{wordsKnown}</div>
                <p className="text-xs text-muted-foreground">Total words learned</p>
              </CardContent>
            </Card>
            <Card className="bg-white bg-opacity-80">
              <CardHeader>
                <CardTitle>Words Due</CardTitle>
                <Calendar className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{wordsDue}</div>
                <p className="text-xs text-muted-foreground">Review these today</p>
              </CardContent>
            </Card>
          </div>
          <Card className="bg-white bg-opacity-80">
            <CardHeader>
              <CardTitle>Course Completions</CardTitle>
              <BookOpen className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {courseCompletions.map(course => (
                  <div key={course.id} className="flex flex-col items-center">
                    <RadialProgress value={course.completion} />
                    <span className="mt-2 text-xs font-medium text-center">{course.title}</span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
          <Card className="bg-white bg-opacity-80">
            <CardHeader>
              <CardTitle>Weekly Activity</CardTitle>
            </CardHeader>
            <CardContent className="pt-6">
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={weeklyActivity}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="cards" stroke="#3b82f6" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
