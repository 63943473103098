import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { Languages } from 'lucide-react';
import { Button } from "./ui/button";
import './CreateCardPopup.css';

// Get the API URL from environment variable
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5001/japan-flash-cards/us-central1';

function CreateCardPopup({ isOpen, onClose, courses, editCard = null, currentCourseId = null }) {
  const [courseId, setCourseId] = useState('');
  const [de, setDe] = useState('');
  const [dejp, setDejp] = useState('');
  const [jp, setJp] = useState('');
  const [info, setInfo] = useState('');
  const [saveNotice, setSaveNotice] = useState('');
  const [errors, setErrors] = useState({});
  const [isTranslating, setIsTranslating] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);

  useEffect(() => {
    if (editCard) {
      setCourseId(editCard.course || '');
      setDe(editCard.de || '');
      setDejp(editCard.dejp || '');
      setJp(editCard.jp || '');
      setInfo(editCard.info || '');
    } else if (currentCourseId) {
      setCourseId(currentCourseId);
    } else if (courses.length > 0) {
      setCourseId(courses[0].id);
    }
  }, [editCard, currentCourseId, courses]);

  const validateForm = () => {
    const newErrors = {};
    if (!courseId) newErrors.courseId = true;
    if (!de) newErrors.de = true;
    if (!dejp) newErrors.dejp = true;
    if (!jp) newErrors.jp = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const clearFields = () => {
    setDe('');
    setDejp('');
    setJp('');
    setInfo('');
  };

  const handleSave = async () => {
    if (!validateForm()) {
      setSaveNotice('Please fill in all required fields.');
      return;
    }

    const firestore = getFirestore();
    try {
      const cardData = { course: courseId, de, dejp, jp };
      if (info.trim() !== '') {
        cardData.info = info;
      }

      if (editCard && editCard.id) {
        await updateDoc(doc(firestore, 'cards', editCard.id), cardData);
        setSaveNotice('Card updated successfully!');
      } else {
        await addDoc(collection(firestore, 'cards'), cardData);
        setSaveNotice('Card saved successfully!');
        if (keepOpen) {
          clearFields();
        }
      }

      setTimeout(() => {
        setSaveNotice('');
        if (!keepOpen) {
          onClose();
        }
      }, 2000);
    } catch (error) {
      console.error('Error saving card:', error);
      setSaveNotice('Error saving card. Please try again.');
    }
  };

  const translateToJapanese = async () => {
    if (!de) {
      setSaveNotice('Please enter a German word to translate.');
      return;
    }

    setIsTranslating(true);
    setSaveNotice('');

    try {
      const response = await fetch(`${API_URL}/translate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: de }),
      });

      if (!response.ok) {
        throw new Error('Translation failed');
      }

      const data = await response.json();
      setJp(data.jp);
      setDejp(data.dejp);
    } catch (error) {
      console.error('Translation error:', error);
      setSaveNotice('Error translating text. Please try again.');
    } finally {
      setIsTranslating(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="dialog-overlay">
      <div className="dialog-content">
        <div className="dialog-header">
          <h2 className="dialog-title">{editCard ? 'Edit Card' : 'Create New Card'}</h2>
        </div>
        <div className="dialog-body">
          <div className="form-group">
            <label htmlFor="course">Course</label>
            <select 
              id="course"
              value={courseId} 
              onChange={(e) => setCourseId(e.target.value)}
              className={errors.courseId ? 'error' : ''}
            >
              <option value="">Select a course</option>
              {courses.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.title || `Course ${course.id}`}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="german">German</label>
            <div className="input-group">
              <input
                id="german"
                type="text"
                placeholder="Enter German word or phrase"
                value={de}
                onChange={(e) => setDe(e.target.value)}
                className={errors.de ? 'error' : ''}
              />
              <Button
                variant="outline"
                size="icon"
                onClick={translateToJapanese}
                disabled={isTranslating}
                className="translate-button"
                aria-label="Translate"
              >
                <Languages className={`h-4 w-4 ${isTranslating ? 'animate-spin' : ''}`} />
              </Button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="japanese">Japanese</label>
            <input
              id="japanese"
              type="text"
              placeholder="Enter Japanese"
              value={jp}
              onChange={(e) => setJp(e.target.value)}
              className={errors.jp ? 'error' : ''}
            />
          </div>
          <div className="form-group">
            <label htmlFor="japanese-romaji">
              Japanese<br />(Romaji)
            </label>
            <input
              id="japanese-romaji"
              type="text"
              placeholder="Enter Japanese in Romaji"
              value={dejp}
              onChange={(e) => setDejp(e.target.value)}
              className={errors.dejp ? 'error' : ''}
            />
          </div>
          <div className="form-group">
            <label htmlFor="additional-info">Additional Information</label>
            <textarea
              id="additional-info"
              placeholder="Enter any additional information"
              value={info}
              onChange={(e) => setInfo(e.target.value)}
            />
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="keepOpen"
              checked={keepOpen}
              onChange={(e) => setKeepOpen(e.target.checked)}
            />
            <label htmlFor="keepOpen">Keep dialog open after saving</label>
          </div>
        </div>
        <div className="dialog-footer">
          <Button onClick={handleSave} className="save-button">Save</Button>
          <Button onClick={onClose} variant="outline" className="cancel-button">Cancel</Button>
        </div>
        {saveNotice && <div className="save-notice">{saveNotice}</div>}
      </div>
    </div>
  );
}

export default CreateCardPopup;
