import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Login from './components/Login';
import Japan from './Japan/Japan';
import Settings from './components/Settings';
import Sidebar from './components/Sidebar';
import SavedCards from './components/SavedCards'; // Add this import
import Dashboard from './components/Dashboard'; // Import the new Dashboard component
import './App.css'; // Make sure to import the CSS file
import MobileMenu from './components/MobileMenu'; // Add this import

function App() {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isGuest, setIsGuest] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const auth = getAuth();
		const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
			setUser(currentUser);
			setIsGuest(currentUser?.isAnonymous || false);
			setLoading(false);
		});

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleGuestRegistration = (newUser) => {
		setUser(newUser);
		setIsGuest(false);
	};

	const ProtectedRoute = ({ children }) => {
		const location = useLocation();
		
		if (loading) {
			return <div>Loading...</div>;
		}

		if (!user) {
			return <Navigate to="/login" state={{ from: location }} replace />;
		}

		return children;
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<Router>
			<div className="app-container">
				{user && !isMobile && (
					<Sidebar 
						onLogout={() => setUser(null)} 
						isGuest={isGuest} 
						onGuestRegister={handleGuestRegistration}
					/>
				)}
				{user && isMobile && (
					<MobileMenu 
						onLogout={() => setUser(null)} 
						isGuest={isGuest} 
						onGuestRegister={handleGuestRegistration}
					/>
				)}
				<div className={`main-content ${isMobile ? 'mobile' : ''}`}>
					<Routes>
						<Route 
							path="/login" 
							element={!user ? <Login onLogin={(user) => setUser(user)} /> : <Navigate to="/" />} 
						/>
						<Route 
							path="/" 
							element={
								<ProtectedRoute>
									<Dashboard />
								</ProtectedRoute>
							} 
						/>
						<Route 
							path="/practice" 
							element={
								<ProtectedRoute>
									<Japan startInPracticeMode={true} />
								</ProtectedRoute>
							} 
						/>
						<Route 
							path="/course/:courseId" 
							element={
								<ProtectedRoute>
									<Japan />
								</ProtectedRoute>
							} 
						/>
						<Route 
							path="/settings" 
							element={
								<ProtectedRoute>
									<Settings />
								</ProtectedRoute>
							} 
						/>
						<Route 
							path="/saved" 
							element={
								<ProtectedRoute>
									<SavedCards />
								</ProtectedRoute>
							} 
						/>
						{/* Catch-all route for undefined paths */}
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</div>
			</div>
		</Router>
	);
}

export default App;
